/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Masonry from 'masonry-layout';
import jQueryBridget from 'jquery-bridget';


require('../jquery-readyselector.js');

jQueryBridget('masonry', Masonry, $);

$(() => {
  $('#tos-form').addClass('modal').modal({
    dismissible: false
  })
  $('#tos-form').modal('open')
  $('#tos-acceptance').addClass('disabled')
  $('#tos_checkbox, #privacy_checkbox').click(function() {
    $('#tos-acceptance').toggleClass('disabled', !($('#tos_checkbox').prop('checked') && $('#privacy_checkbox').prop('checked')))
  })

  $("#tos-acceptance").click(function() {
    const userId = $(this).data('userId')

    $.ajax({
      url: '/user-accept-tos',
      type: 'POST',
      dataType: 'json',
      data: { userId },
      beforeSend: () => {
        $("#tos-acceptance").addClass('disabled')
      },
      success: (data) => {
        $('#tos-form').modal('close')
      },
      error: () => {
        window.location.reload()
      }
    });
  })

  document.addEventListener('keydown', function(event){
    if (event.keyCode == 69 /* nice */ && event.ctrlKey) { // This is to listen for ctrl + c
      event.preventDefault();
      railsConsole()
    }
  });

  $('.banner-dismiss-button').click(function() {
    const { type, remaining } = $(this).data();
    const cookies = Cookies.getJSON('dismissed_banners') || {};

    cookies[type] = true;
    Cookies.set('dismissed_banners', cookies, { expires: remaining + 1 });

    $(this).closest('li').remove();
    !$('.message-banner ul').children().length && $('.message-banner').remove();
  });
})

export const loadingHtml = '<style>.form-loader{position:relative;z-index:10;left:50%;margin-left:-35px;border:4px solid #aaa;border-top:4px solid #000;border-radius:50%;width:70px;height:70px;-webkit-animation:spin 1.5s linear infinite;animation:spin 1.5s linear infinite}.form-title{font-size:20px;margin:-7px 0 14px}</style><div class=card-content><div class="center-align form-title">Loading</div><div class=form-loader></div></div>';

export const searchInit = () => {
  $('.search-box').keyup(function() {
    const items = $('.search-item');
    let term = this.value.match(/\b(\w|\%)+/g);

    if (term) {
      term = term.join('').toLowerCase();

      items.each(function() {
        const text = this.innerHTML.toLowerCase().match(/\b(\w|\%)+/g).join('');
        $(this).toggle(text.includes(term));
      });
    } else {
      items.show();
    }
  });
}

export const fileUploadInit = (inputId, wrapperId) => {
  var input = $(inputId);
  var inputWrapper = $(wrapperId).click(function() {
    input.click();
  });

  input.change(function() {
    inputWrapper.html((input[0].files[0] || {}).name || 'Choose a file');
  });
}